import * as React from "react";
import { graphql } from "gatsby";
import { YouTubeFields } from "./fragments/NodeYouTubeFields";
import { Media } from "../components/common/MediaGallery/classes/Media";
import MediaGalleryType from "../components/common/MediaGallery/MediaGalleryType";
import MediaGalleryCats from "../components/common/MediaGallery/MediaGalleryCats";
import { WmkSeo } from "wmk-seo";

export type MediaTypes = "photos" | "videos";

export interface TagQuery {
  name: string;
  slug: string;
}

type MediaTagContext =
  | { type: MediaTypes; category: TagQuery }
  | { type: MediaTypes; categories: TagQuery[] };

const MediaTag = ({
  data,
  pageContext
}: {
  data: MediaTagQuery;
  pageContext: MediaTagContext;
}) => {
  const media = new Media(data);
  const isParent = "categories" in pageContext ? true : false;
  const category =
    "categories" in pageContext
      ? pageContext.type
      : pageContext?.category?.name;

  return (
    <>
      <WmkSeo.Meta
        title={`${category} gallery`}
        description={`${category} resource gallery`}
        path={
          isParent
            ? `/resources/media/`
            : `/resources/media/${pageContext.type}/`
        }
        slug={isParent ? pageContext.type : "category" in pageContext ? pageContext?.category?.slug : ""}
        siteTitle={"UTRWD"}
        baseUrl="https://www.utrwd.com"
      />
      <div style={{ padding: `0 0 2rem 0` }}>
        {"categories" in pageContext ? (
          <MediaGalleryType
            {...pageContext}
            media={pageContext.type in media ? media[pageContext.type] : []}
          />
        ) : (
          <MediaGalleryCats {...pageContext} media={media} />
        )}
      </div>
    </>
  );
};

export default MediaTag;

interface MediaTagQuery {
  videos: {
    edges: {
      node: YouTubeFields;
    }[];
  };
}

export const query = graphql`
  {
    videos: allYoutubeVideo {
      edges {
        node {
          ...NodeYoutubeFields
        }
      }
    }
    photos: allFlickrPhoto {
      edges {
        node {
          ...NodeFlickrFields
        }
      }
    }
  }
`;
