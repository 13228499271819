import * as React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { Tag } from "wmk-tag";
import { TagQuery } from "../../../../node/mediaTag";

import { MediaItem } from "../classes/Media";
import { MediaTileContent, MediaTileImage } from "./MediaItemTile";

const MediaCol = styled(Col)`
  margin: 1rem 0;
  & > div {
    position: relative;
  }
  .image,
  .content,
  .thumb-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .thumb {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .content {
    padding: 0.5rem;
  }
`;

const MediaCatTile = ({
  category,
  type,
  media
}: {
  category: TagQuery;
  type: "videos" | "photos";
  media: MediaItem[];
}) => {
  const item = media[0];
  return (
    <MediaCol xs={12} md={4} lg={3}>
      <div>
        <MediaTileImage item={item} />
        <WmkLink to={`/resources/media/${type}/${category.slug}`}>
          <MediaTileContent item={item} category={new Tag(category)} />
        </WmkLink>
      </div>
    </MediaCol>
  );
};

export default MediaCatTile;
