import * as React from "react";
import { Container, Row } from "react-bootstrap";
import { MediaTypes, TagQuery } from "../../../node/mediaTag";
import PageHero from "../PageHero";
import { MediaItem } from "./classes/Media";
import { Breadcrumbs } from "./parts/Breadcrumbs";
import MediaCatTile from "./parts/MediaCatTile";

const MediaGalleryType = ({
  type,
  categories,
  media
}: {
  type: MediaTypes;
  categories: TagQuery[];
  media: MediaItem[];
}) => {
  return (
    <>
      <PageHero title={type} />
      <Container>
        <Breadcrumbs type={type} />
        <Row>
          {categories.map((c, i) => {
            const catMedia = media.reduce((matches, item) => {
              item.tags.forEach((t) => {
                if (t.slug === c.slug) {
                  matches.push(item);
                }
              });
              return matches;
            }, []);
            return catMedia.length > 0 ? (
              <MediaCatTile
                key={c.name + i}
                category={c}
                type={type}
                media={catMedia}
              />
            ) : null;
          })}
        </Row>
      </Container>
    </>
  );
};

export default MediaGalleryType;
