import * as React from "react";
import { Typography } from "../../ui/Typography";
import { Media, MediaItem, MediaPhoto, MediaVideo } from "./classes/Media";
import PageHero from "../PageHero";
import { MediaTypes, TagQuery } from "../../../node/mediaTag";
import { Container, Row } from "react-bootstrap";
import MediaItemTile from "./parts/MediaItemTile";
import { Breadcrumbs } from "./parts/Breadcrumbs";
import { graphql, useStaticQuery } from "gatsby";
import { WmkLink } from "wmk-link";
import styled from "styled-components";
import { colors } from "../../ui/colors";

const MediaGalleryCats = ({
  type,
  category,
  media
}: {
  type: MediaTypes;
  category: TagQuery;
  media: Media;
}) => {
  const data: CategoryLinks = useStaticQuery(query);
  const catUrl = data.links.menuItems.reduce(
    (url: string, link: { title: string; url: string }) => {
      return link.title === category.name ? link.url : url;
    },
    ""
  );
  const items: (MediaPhoto | MediaVideo)[] = type in media ? media[type] : [];
  const mediaItems: MediaItem[] = items.reduce(
    (matches: MediaItem[], item: MediaItem) => {
      item.tags.forEach((tag) => {
        if (tag.slug === category.slug) {
          matches.push(item);
        }
      });
      return matches;
    },
    []
  );
  return (
    <>
      <PageHero title={category.name + " " + type} />
      <Container>
        <Breadcrumbs type={type} currCat={category} />
        <Typography.H2>{category.name}</Typography.H2>
        {catUrl !== "" ? (
          <CategoryLink category={category} url={catUrl} />
        ) : null}
        <Row>
          {mediaItems.map((m, i) => {
            return <MediaItemTile key={m.title + i} item={m} type={type} />;
          })}
        </Row>
      </Container>
    </>
  );
};

export default MediaGalleryCats;

const StyledCategoryLink = styled.span`
  padding: 0 0 0.5rem 0;
  display: block;
  a {
    color: ${colors.orange.toString()};
  }
`;

const CategoryLink = ({
  category,
  url
}: {
  category: TagQuery;
  url: string;
}) => (
  <StyledCategoryLink>
    View more videos on {category.name}{" "}
    <WmkLink to={url} target="blank">
      here
    </WmkLink>
    .
  </StyledCategoryLink>
);

interface CategoryLinks {
  links: {
    menuItems: {
      title: string;
      url: string;
    }[];
  };
}

const query = graphql`
  {
    links: contentfulMenuContainer(title: { eq: "UTRWD Video Links" }) {
      menuItems {
        ... on ContentfulMenuItem {
          title
          url
        }
      }
    }
  }
`;
