import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { GatsbyImageData } from "wmk-image";
import { WmkLink } from "wmk-link";
import { Tag } from "wmk-tag";
import { slugify } from "../../../routes/Home/NewsAndEvents/NewsFeed/PostTag";
import { colors } from "../../../ui/colors";
import { Typography } from "../../../ui/Typography";
import { MediaItem } from "../classes/Media";

export const MediaItemImage = ({
  image,
  alt
}: {
  image: string | GatsbyImageData;
  alt: string;
}) => {
  return typeof image === "string" ? (
    <img src={image} alt={alt} className="thumb" />
  ) : (
    <GatsbyImage image={image} alt={alt} className="thumb-wrap" />
  );
};

export const MediaTileImage = ({ item }: { item: MediaItem }) => {
  return (
    <>
      <StaticImage
        alt=""
        src="https://dummyimage.com/16x9/ffffff/ffffff.png"
        className="square"
        layout="fullWidth"
      />
      <div className="image">
        <MediaItemImage
          alt={item.title}
          image={
            "thumb" in item
              ? item.thumb
              : "image" in item
              ? item.image
              : undefined
          }
        />
      </div>
    </>
  );
};

const StyledContent = styled.div<{ cat: number }>`
  ${({ cat }) =>
    cat
      ? ``
      : `&:hover {
    .title {
      transform: translateY(0);
      transition: all 0.3s ease;
    }
  }`}
  .content {
    position: relative;
    ${({ cat }) =>
      cat
        ? `background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 33%,
      rgba(0, 0, 0, 0) 60%
    );`
        : ``}
    color: white;
    transition: all 0.3s ease;
    overflow: hidden;
    &:hover {
      color: ${({ cat }) =>
        cat ? colors.orange.toString() : colors.white.toString()};
      transition: all 0.3s ease;
    }
    ${({ cat }) =>
      !cat
        ? `
        padding: 0 !important; 
        & > div {
       
      position: absolute;
      width: 100%;
      padding: 0.3rem 0.5rem 0.5rem 0.5rem;
      display: flex;
      line-height: 1;
      background: ${colors.blue.alpha(0.8).toString()};
      bottom: 0;
      left: 0;
      transform: translateY(100%);    
      transition: all .3s ease;

      & > * {
        margin: 0;
      }
    }`
        : ``}
  }
`;

export const MediaTileContent = ({
  item,
  category
}: {
  item: MediaItem;
  category?: Tag;
}) => {
  return (
    <StyledContent cat={category ? 1 : 0}>
      <div className="content">
        <div className="title">
          <Typography.H6>{category ? category.name : item.title}</Typography.H6>
        </div>
      </div>
    </StyledContent>
  );
};

const MediaCol = styled(Col)`
  margin: 1rem 0;
  & > div {
    position: relative;
  }
  .image,
  .content,
  .thumb-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .thumb {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .content {
    padding: 0.5rem;
  }
`;

const MediaItemTile = ({
  item,
  type
}: {
  item: MediaItem;
  type: "videos" | "photos";
}) => {
  return (
    <MediaCol xs={12} md={4} lg={3}>
      <div>
        <MediaTileImage item={item} />
        <WmkLink to={`/resources/media/${type}/${slugify(item.title)}`}>
          <MediaTileContent item={item} />
        </WmkLink>
      </div>
    </MediaCol>
  );
};

export default MediaItemTile;
